<template>
  <v-card class="mt-n10 moholand-shadow">
    <v-card-title>
      ایجاد بانک جدید
      <v-spacer></v-spacer>

      <v-btn color="primary" :to="destinationRoute">
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />
        <v-row align="center" justify="end" class="mt-8">
          <v-btn @click="clear" depressed>
            پاک کردن فرم
          </v-btn>

          <v-btn
            class="mx-4"
            dark
            color="success"
            :loading="loading"
            type="submit"
          >
            ایجاد بانک جدید
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import VFormBase from "vuetify-form-base";
import { formPostPrepare } from "@/constants/PanelFormHandler";
export default {
  components: {
    VFormBase,
  },
  data() {
    return {
      rules: Constants.rules,
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("create") - 1
        ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      loading: false,
      model: {
        name: "",
        sheba: "",
        cart_number: "",
      },
      schema: {
        name: {
          type: "CustomInput",
          label: "نام بانک",
          rules: [Constants.rules.required],
          required: true,
        },
        cart_number: {
          type: "CustomInput",
          label: "شماره کارت",
          class: "ltr",
          rules: [
            Constants.rules.required,
            Constants.rules.withoutSpace,
            Constants.rules.numeric,
          ],
          required: true,
        },
        sheba: {
          type: "CustomInput",
          label: "شماره شبا",
          prefix: "IR",
          class: "ltr",
          rules: [
            // Constants.rules.required,
            Constants.rules.withoutSpace,
            Constants.rules.numeric,
          ],
          col: {cols: 12},
          required: true,
        },
      },
    };
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        let data = formPostPrepare(this.model);
        data.append("user_id", this.$store.state?.userData?.id)

        MyAxios.post("banks/create", data)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "بانک با موفقیت ایجاد شد.",
              type: "success",
            });
            console.log(response);
            this.$router.replace(this.destinationRoute);
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "مشکلی پیش آمده است. لطفا دوباره سعی کنید",
            });
            console.log(error);
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
    clear() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
